// $grid-breakpoints: (
// 	xs: 0,
// 	sm: 576px,
// 	md: 768px,
// 	lg: 992px,
// 	xl: 1200px,
// 	xxl: 1400px,
// );

$top-main-gap: 64px;
$dirty-top-main-gap: 56px;
$dirty-main-gap: $top-main-gap - 8;
$default-transition-duration: 0.3s;

$primary-dark-text: rgba(0, 0, 0, 0.87);
$secondary-dark-text: rgba(0, 0, 0, 0.54);
$disabled-dark-text: rgba(0, 0, 0, 0.38);
$divider-dark-text: rgba(0, 0, 0, 0.12);
$primary-light-text: #fff;
$secondary-light-text: rgba(255, 255, 255, 0.7);
$disabled-light-text: rgba(255, 255, 255, 0.5);
$divider-light-text: rgba(255, 255, 255, 0.12);
$cool-dark-color: #495057;

$primary-dark-text-colors: #212020 $primary-dark-text;
$secondary-dark-text-colors: #757373 $secondary-dark-text;
$disabled-dark-text-colors: #9d9c9b $disabled-dark-text;
$divider-dark-text-colors: #dfdcdc $divider-dark-text;
$primary-light-text-colors: $primary-light-text;
$secondary-light-text-colors: #b3b3b3 $secondary-light-text;
$disabled-light-text-colors: #808080 $disabled-light-text;
$divider-light-text-colors: #1f1f1f $divider-light-text;

:root {
	--bs-font-sans-serif: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
		'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	--bs-font-monospace: 'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
		monospace;
	--bs-font-serif: 'Lora', serif;
	--primary-dark-text: rgba(0, 0, 0, 0.87);
	--secondary-dark-text: rgba(0, 0, 0, 0.54);
	--disabled-dark-text: rgba(0, 0, 0, 0.38);
	--divider-dark-text: rgba(0, 0, 0, 0.12);
	--primary-light-text: #fff;
	--secondary-light-text: rgba(255, 255, 255, 0.7);
	--disabled-light-text: rgba(255, 255, 255, 0.5);
	--divider-light-text: rgba(255, 255, 255, 0.12);
	--cool-main-text: #495057;
	--transition-duration: 0.25s;

	--white-glass-color: rgba(255, 255, 255, 0.8);
	--dark-glass-color: rgba(0, 0, 0, 0.5);

	// --highlight-color: var(--bs-emphasis-color);
	// --highlight-background-color: var(--bs-warning);
	// --highlight-border-color: transparent;
	--highlight-color: var(--bs-warning-text-emphasis);
	--highlight-background-color: var(--bs-warning-bg-subtle);
	--highlight-border-color: var(--bs-warning-border-subtle);
	--stripped-row-color: var(--bs-light);

	// --bs-body-bg: #fefbfa;

	// @each $screen, $size in $grid-breakpoints {
	// 	--screen-width-#{$screen}: #{$size};
	// }

	--greyer-skeleton-base-color: #d9d9d9;
}
[data-bs-theme='dark'] {
	--cool-main-text: var(--bs-secondary-color);
	--auxiliary-image-opacity: 0.75;

	--highlight-color: var(--bs-warning-text-emphasis);
	--highlight-background-color: var(--bs-warning-bg-subtle);
	--highlight-border-color: var(--bs-warning-border-subtle);
	--stripped-row-color: #2c3034;

	--white-glass-color: rgba(0, 0, 0, 0.5);
	--dark-glass-color: rgba(255, 255, 255, 0.8);

	--bs-dark-rgb: 23, 27, 31;

	.table-themed {
		--bs-table-color: var(--bs-body-color);
		--bs-table-bg: #212529;
		--bs-table-border-color: #373b3e;
		--bs-table-striped-bg: #2c3034;
		--bs-table-striped-color: var(--bs-body-color);
		--bs-table-active-bg: #373b3e;
		--bs-table-active-color: var(--bs-body-color);
		--bs-table-hover-bg: #323539;
		--bs-table-hover-color: var(--bs-body-color);
		color: var(--bs-table-color);
		border-color: var(--bs-table-border-color);
	}

	.themed-select__control,
	.themed-select__menu {
		background-color: var(--bs-body-bg) !important;
	}
	.themed-select__single-value,
	.themed-select__input-container,
	.themed-select__multi-value__label {
		color: var(--bs-body-color) !important;
	}
	.themed-select__multi-value {
		color: var(--bs-body-color) !important;
		background-color: var(--bs-secondary-bg) !important;
	}
	.themed-select__multi-value__remove:hover {
		background-color: rgba(255, 0, 0, 0.2);
	}
	.themed-select__option--is-focused {
		color: var(--bs-body-color) !important;
		background-color: var(--bs-secondary-bg) !important;
	}
	.themed-select__option--is-selected {
		color: var(--bs-body-bg) !important;
		background-color: var(--bs-primary) !important;
	}

	.react-loading-skeleton {
		--base-color: var(--bs-secondary-bg);
		--highlight-color: var(--bs-tertiary-bg);
	}
}

@mixin size($width, $height) {
	width: $width;
	height: $height;
}

@mixin square($size) {
	@include size($size, $size);
}

// @mixin colors($colors) {
// 	@each $color in $colors {
// 		color: $color;
// 	}
// }

%primary-dark-text {
	color: var(--primary-dark-text);
	// @include colors($primary-dark-text-colors);
}
.primary-dark-text {
	@extend %primary-dark-text;
}
// %secondary-dark-text {
// 	color: var(--secondary-dark-text);
// }
// .secondary-dark-text {
// 	@extend %secondary-dark-text;
// }

%secondary-light-text {
	color: var(--secondary-light-text);
	// @include colors($secondary-light-text-colors);
}
.secondary-light-text {
	@extend %secondary-light-text;
}

%cool-dark-color {
	color: var(--cool-main-text) !important;
}
.cool-dark-color,
.text-cool {
	@extend %cool-dark-color;
}

%bg-default {
	background-color: var(--bs-body-bg);
}

%default-font,
%sans-serif-font {
	font-family: var(--bs-font-sans-serif);
}

%serif-font {
	font-family: var(--bs-font-serif);
}

.fw-500 {
	font-weight: 500;
}

.border-transparent {
	--bs-border-opacity: 0;
	border-color: rgba(0, 0, 0, var(--bs-border-opacity)) !important;
}

.text-small {
	font-size: 0.8em;
}

.bg-default {
	@extend %bg-default;
}

.top-main-gap {
	top: $top-main-gap;
}
.dirty-main-gap {
	top: $dirty-main-gap;
}

.min-width-0 {
	min-width: 0;
}

// .mv-50vw {
// 	max-width: 50vw;
// }

// .top-bottom-0 {
// 	top: 0;
// 	bottom: 0;
// }

.mt-n1 {
	margin-top: -0.25rem !important;
}
.mb-n1 {
	margin-bottom: -0.25rem !important;
}
.my-n1 {
	margin-top: -0.25rem !important;
	margin-bottom: -0.25rem !important;
}
.ms-n1 {
	margin-left: -0.25rem !important;
}

.mt-n2 {
	margin-top: -0.5rem !important;
}
.me-n2 {
	margin-right: -0.5rem !important;
}

.mt-n3 {
	margin-top: -1rem !important;
}

.center-with-transform {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.white-glass {
	background-color: var(--white-glass-color);
	color: var(--bs-emphasis-color);
}
.dark-glass {
	background-color: var(--dark-glass-color);
	color: var(--bs-secondary-bg);
}

.h-25v {
	height: 25vh;
}
.h-75v {
	height: 75vh;
}

textarea.no-resize {
	resize: none;
}

body {
	@extend %bg-default;
	@extend %default-font;
}

#root {
	padding-top: $top-main-gap;
}

.cursor-pointer {
	cursor: pointer;
}

.semi-bold {
	font-weight: 500;
}

.serif-font {
	@extend %serif-font;
}

@keyframes keyframe-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.animation-spin {
	animation: keyframe-spin var(--animation-duration, 1s) infinite linear;
	// display: inline-block;
}

@keyframes keyframe-random-face {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	40% {
		transform: scale(1.5);
		opacity: 0;
	}
	60% {
		transform: scale(0.5);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
// @keyframes keyframe-random-face-x {
// 	0% {
// 		transform: translateX(0);
// 		opacity: 1;
// 	}
// 	45% {
// 		transform: translateX(62%);
// 		opacity: 0;
// 	}
// 	55% {
// 		transform: translateX(-62%);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateX(0);
// 		opacity: 1;
// 	}
// }
// @keyframes keyframe-random-face-y {
// 	0% {
// 		transform: translateY(0);
// 		opacity: 1;
// 	}
// 	40% {
// 		transform: translateY(50%);
// 		opacity: 0;
// 	}
// 	60% {
// 		transform: translateY(-100%);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateY(0);
// 		opacity: 1;
// 	}
// }

@keyframes keyframe-bounce {
	0% {
		transform: scale(0.62);
	}
	50% {
		transform: scale(1.61);
	}
	100% {
		transform: scale(0.62);
	}
}
.animation-bounce {
	animation: keyframe-bounce 2s infinite linear;
	display: inline-block;
}

@keyframes keyframes-highlight {
	0% {
		background-color: var(--bs-warning, gold);
	}
	100% {
		background-color: transparent;
	}
}
.animation-highlighted {
	animation: 1s linear alternate 0.1s 1 keyframes-highlight;
}

@keyframes preloaderAnimation {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
		background-color: var(--bs-gray-500);
	}
	100% {
		transform: scale(0);
	}
}

.html-document-container {
	@extend %serif-font;
	// h1,
	// h2,
	// h3,
	// h4,
	// h5,
	// h6 {
	// 	@extend %default-font;
	// }
	p {
		// line-height: 1.2;
		font-size: var(--msg-text-size, 24px);
		@media screen and (max-width: 768px) {
			--msg-text-size: 20px;
			// word-wrap: break-word;
			// word-break: break-word;
		}
	}
	table {
		p {
			--msg-text-size: 20px;
		}
	}
	img {
		max-width: 62% !important;
	}
	td {
		min-width: 15vmin;
		& > p:last-child {
			margin-bottom: 0;
		}
	}
	$base-size: 6vmin;
	img[alt*='strabis'] {
		width: $base-size * 3;
		height: $base-size * 4;
		object-fit: cover;
		opacity: var(--auxiliary-image-opacity);
	}
	img[alt*='map'] {
		// width: $base-size / 10 * 3;
		// height: $base-size / 10 * 4;
		width: 16px;
		height: 16px;
		object-fit: cover;
	}
}

.not-auth {
	#root {
		background-color: #550000;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23550000' cx='50' cy='0' r='50'/%3E%3Cg fill='%235d0002' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23650004' cx='50' cy='100' r='50'/%3E%3Cg fill='%236d0006' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23750007' cx='50' cy='200' r='50'/%3E%3Cg fill='%237d0009' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%2385000a' cx='50' cy='300' r='50'/%3E%3Cg fill='%238d000a' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%2396000b' cx='50' cy='400' r='50'/%3E%3Cg fill='%239e000b' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a7000b' cx='50' cy='500' r='50'/%3E%3Cg fill='%23af000b' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b8000b' cx='50' cy='600' r='50'/%3E%3Cg fill='%23c1000a' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ca000a' cx='50' cy='700' r='50'/%3E%3Cg fill='%23d20009' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23db0007' cx='50' cy='800' r='50'/%3E%3Cg fill='%23e40006' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ed0004' cx='50' cy='900' r='50'/%3E%3Cg fill='%23f60002' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23FF0000' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
		background-attachment: fixed;
		background-size: contain;
	}
}

.doc-score__container {
	// margin-top: 0.55em;
	gap: 2px;
}
.doc-score__item {
	background-color: var(--score-bg-color, var(--bs-gray-400));
	width: 20px;
	height: 5px;
}
.doc-score__item_green {
	--score-bg-color: var(--bs-green);
}

.icon-lg {
	scale: 138%;
}
.icon-xl {
	scale: 162%;
}

.text-hl {
	/* color: var(--bs-danger, red); */
	color: var(--highlight-color);
	background-color: var(--highlight-background-color);
	padding: 2px 4px;
	border-radius: 4px;
	font-style: normal;
	border: 1px solid var(--highlight-border-color);
	/* font-weight: 500; */
}

.logout__container {
	.logout__user-name {
		max-width: 0;
		transition: all var(--transition-duration) linear 0.6s;
		opacity: 0;
	}
	&:hover {
		.logout__user-name {
			max-width: 200px;
			opacity: 1;
		}
	}
}

.table-themed {
	--bs-table-color: #000;
	--bs-table-bg: #f8f9fa;
	--bs-table-border-color: #dfe0e1;
	--bs-table-striped-bg: #ecedee;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfe0e1;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e5e6e7;
	--bs-table-hover-color: #000;
	color: var(--bs-table-color);
	border-color: var(--bs-table-border-color);
}

// ---------- Zoomable images ----------
.img-zoomable {
	cursor: zoom-in;
}

.modal-zoom {
	$w: 90vw;
	$h: 90vh;
	max-width: $w !important;
	max-height: $h !important;
	cursor: zoom-out;
	.modal-content {
		background-color: transparent;
		border: none;
		img {
			max-width: $w;
			max-height: $h;
			object-fit: contain;
			background-color: #fff;
			box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
		}
	}
}

.show-only-child:not(:only-child) {
	display: none;
}

.fi.country-flag {
	// width: var(--country-flag-width, 1.53em);
	// height: var(--country-flag-height, 1.1475em);
	// width: var(--country-flag-width, 1.4em);
	// height: var(--country-flag-height, 1.05em);
	width: var(--country-flag-width, 1.333em);
	height: var(--country-flag-height, 1em);
	&.fis {
		--country-flag-width: 1em;
		--country-flag-height: 1em;
	}
}
// .shadow-country-flag {
// 	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
// }

.form-label {
	white-space: nowrap;
}

.item-edit_form:nth-of-type(even) {
	background-color: var(--stripped-row-color);
}

// .table__caption_top {
// 	caption-side: top;
// }

// .check-box_fine-align {
// 	line-height: 1.6;
// }

.table-color-danger {
	--bs-table-color: var(--bs-danger);
	--bs-table-striped-color: var(--bs-danger);
}

.table-smart-border {
	tr:last-child:nth-child(even) {
		// border-bottom: 1px solid rgb(var(--bs-secondary-rgb), 0.25);
		border-bottom: 3px solid var(--bs-table-striped-bg);
	}
}

.tr-top-separator {
	border-top: 3px solid var(--bs-secondary-border-subtle) !important;
	// border-top: 1px solid var(--bs-secondary) !important;
}

.link-button {
	background-color: transparent;
	border: none;
	// cursor: pointer;
	// text-decoration: underline;
	// display: inline;
	margin: 0;
	padding: 0;
}

// .text-black-emboss {
// 	text-shadow: 0 -1px black;
// }

// .text-white-emboss {
// 	text-shadow: 0 1px white;
// }
